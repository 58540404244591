<ng-container *transloco="let t">
    <!-- Button -->
    <button
        mat-icon-button
        [matTooltip]="t('theme-picker.choose')"
        [matMenuTriggerFor]="mode"
    >
        <mat-icon svgIcon="heroicons_outline:swatch"></mat-icon>
    </button>

    <!-- Language menu -->
    <mat-menu [xPosition]="'before'" #mode="matMenu">
        <button mat-menu-item (click)="setActiveThemeMode('auto')">
            <span class="flex items-center">
                <mat-icon svgIcon="heroicons_outline:bolt"></mat-icon>
                <span class="ml-3">{{ t('theme-picker.auto') }}</span>
            </span>
        </button>
        <button mat-menu-item (click)="setActiveThemeMode('light')">
            <span class="flex items-center">
                <mat-icon svgIcon="heroicons_outline:sun"></mat-icon>
                <span class="ml-3">{{ t('theme-picker.light') }}</span>
            </span>
        </button>
        <button mat-menu-item (click)="setActiveThemeMode('dark')">
            <span class="flex items-center">
                <mat-icon svgIcon="heroicons_outline:moon"></mat-icon>
                <span class="ml-3">{{ t('theme-picker.dark') }}</span>
            </span>
        </button>
    </mat-menu>
</ng-container>
