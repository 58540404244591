<div class="fixed top-0 bottom-0 w-full" *transloco="let t">
    <div
        class="flex flex-col w-full md:w-2/5 h-full transition-transform duration-400 ease-drawer bg-card"
        [ngClass]="{'-translate-x-full shadow': opened, 'translate-x-0': !opened}">

        <!-- Header -->
        <div
            class="ai-chat-header flex flex-0 items-center justify-start">

            <div class="flex flex-auto items-center justify-between">
                <div class="flex flex-auto items-center">
                    <div class="flex flex-0 items-center justify-center w-16">
                        <mat-icon
                            class="icon-size-6"
                            [svgIcon]="'treebyte-ai-chat'"></mat-icon>
                    </div>
                    <div class="text-lg font-medium text-secondary">{{t('components.ai-chat.ai-chat-title', {botName: AI_CONSTANTS.BOT_NAME})}}</div>
                </div>
                <div>
                    <button
                        class="ml-auto mr-4"
                        mat-icon-button
                        (click)="initChat()"
                        [matTooltip]="t('generic.reset')">
                        <mat-icon [svgIcon]="'heroicons_outline:arrow-path'"></mat-icon>
                    </button>
                    <button
                        class="ml-auto mr-4"
                        mat-icon-button
                        (click)="toggle()"
                        [matTooltip]="t('generic.close')">
                        <mat-icon [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                    </button>
                </div>
            </div>
        </div>

        <!-- Content -->
        <div class="flex flex-auto border-t overflow-hidden">

            <!-- Conversation -->
            <div
                class="flex flex-col flex-auto justify-between border-l overflow-hidden bg-gray-50 dark:bg-transparent">
                @if (chat) {
                    <div class="flex flex-col-reverse overflow-y-auto overscroll-y-contain">
                        <div class="flex flex-col flex-auto shrink p-6">
                            @for (message of chat.messages; track trackByFn(i, message); let i = $index; let first = $first; let last = $last) {
                                <div
                                    class="flex flex-col"
                                    [ngClass]="{'items-end': message.isMine,
                                                'items-start': !message.isMine,
                                                'mt-0.5': i > 0 && chat.messages[i - 1].isMine === message.isMine,
                                                'mt-3': i > 0 && chat.messages[i - 1].isMine !== message.isMine}">
                                    <!-- Bubble -->
                                    <div
                                        class="relative max-w-3/4 px-3 py-2 rounded-lg"
                                        [ngClass]="{'bg-blue-500 text-blue-50': message.isMine,
                                                    'text-gray-50': !message.isMine,
                                                    'bg-gray-500': !message.isMine && (!chat.errorOccurred || !last),
                                                    'bg-warn-400': !message.isMine && chat.errorOccurred && last}">
                                        <!-- Speech bubble tail -->
                                        @if (last || chat.messages[i + 1].isMine !== message.isMine) {
                                            <div
                                                class="absolute bottom-0 w-3"
                                                [ngClass]="{'text-blue-500 -right-1 -mr-px mb-px': message.isMine,
                                                            '-left-1 -ml-px mb-px -scale-x-1': !message.isMine,
                                                            'text-gray-500': !message.isMine && (!chat.errorOccurred || !last),
                                                            'text-warn-400': !message.isMine && chat.errorOccurred && last}">
                                                <ng-container *ngTemplateOutlet="speechBubbleExtension"></ng-container>
                                            </div>
                                        }
                                        <!-- Message -->
                                        <span
                                            class="min-w-4 leading-5"
                                            [innerHTML]="message.content">
                  </span>
                                    </div>
                                    <!-- Time -->
                                    @if (first
                                    || last
                                    || chat.messages[i + 1].isMine !== message.isMine) {
                                        <div
                                            class="my-0.5 text-sm font-medium text-secondary"
                                            [ngClass]="{'mr-3': message.isMine,
                                                        'ml-3': !message.isMine}">
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                    <!-- Message field -->
                    <div class="sticky bottom-0 flex items-end p-4 border-t bg-gray-50 dark:bg-transparent">
                        <mat-form-field
                            class="fuse-mat-dense fuse-mat-rounded fuse-mat-bold w-full"
                            [subscriptSizing]="'dynamic'"
                            (keydown.enter)="chatWithAi($event, messageInput)">
            <textarea
                matInput
                cdkTextareaAutosize
                cdkAutosizeMaxRows="6"
                [disabled]="chat.errorOccurred"
                class="max-h-2/5"
                #messageInput></textarea>
                        </mat-form-field>
                        <div class="flex items-center h-11 my-px ml-4">
                            <button
                                [disabled]="chat.errorOccurred"
                                mat-icon-button
                                (click)="chatWithAi($event, messageInput)"
                                [matTooltip]="t('generic.send')">
                                <mat-icon [svgIcon]="'heroicons_outline:paper-airplane'"></mat-icon>
                            </button>
                        </div>
                    </div>
                } @else {
                    <div class="flex flex-col flex-auto items-center justify-center w-full h-full p-4">
                        <mat-icon
                            class="icon-size-24"
                            [svgIcon]="'heroicons_outline:chat-bubble-bottom-center-text'"></mat-icon>
                        <div class="mt-4 text-xl text-center font-medium tracking-tight text-secondary"></div>
                    </div>
                }
            </div>
        </div>
    </div>
</div>

<!-- Select chat or start new template -->

<!-- Speech bubble tail SVG -->
<!-- @formatter:off -->
<ng-template #speechBubbleExtension>
  <svg width="100%" height="100%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path d="M1.01522827,0.516204834 C-8.83532715,54.3062744 61.7609863,70.5215302 64.8009949,64.3061218 C68.8074951,54.8859711 30.1663208,52.9997559 37.5036011,0.516204834 L1.01522827,0.516204834 Z" fill="currentColor" fill-rule="nonzero"></path>
    </g>
  </svg>
</ng-template>
<!-- @formatter:on -->
