import { Component } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuItem } from '@angular/material/menu';

import { MatTooltip } from '@angular/material/tooltip';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
    selector: 'wiki',
    standalone: true,
    imports: [
        MatDivider,
        MatIcon,
        MatIconButton,
        MatMenu,
        MatMenuItem,
        TranslocoDirective,
        MatTooltip,
    ],
    templateUrl: './wiki.component.html',
})
export class WikiComponent {
    openWiki() {
        window.open('https://wiki.stockstore.cloud', '_blank');
    }
}
