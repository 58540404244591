import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import '@microsoft/applicationinsights-analytics-js';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})

// Singleton service that initializes Azure Application Insights monitoring inside its constructor.
// To enable Application Insights just inject this service into app.component
export class MonitoringService {
    constructor(private router: Router) {
        if (!environment.applicationInsights?.connectionString) {
            return;
        }

        let angularPlugin = new AngularPlugin();
        const appInsights = new ApplicationInsights({
            config: {
                connectionString:
                    environment.applicationInsights.connectionString,
                enableAjaxErrorStatusText: true,
                extensions: [angularPlugin],
                extensionConfig: {
                    [angularPlugin.identifier]: { router: this.router },
                },
            },
        });

        appInsights.addTelemetryInitializer((telemetry) => {
            if (telemetry.tags) {
                let roleName = environment.applicationInsights.serviceName;

                if (!roleName || roleName == '') {
                    roleName = 'Backoffice';
                }

                telemetry.tags['ai.cloud.role'] = roleName;
            }
        });

        appInsights.loadAppInsights();
        appInsights.trackPageView();
    }
}
