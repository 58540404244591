<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
    class="dark bg-gray-900 print:hidden"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation.default"
    [opened]="!isScreenSmall"
>
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="mt-4 flex h-20 items-center pb-16 pt-10">
            <!-- Light version -->
            <img
                class="mx-auto w-30 dark:hidden"
                src="/images/logo/logo-text.png"
                alt="Logo image"
            />
            <!-- Dark version -->
            <img
                class="mx-auto hidden w-30 dark:flex"
                src="/images/logo/logo-text-on-dark.png"
                alt="Logo image"
            />
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div *transloco="let t" class="flex w-full min-w-0 flex-auto flex-col">
    <!-- Header -->
    <div
        class="bg-card relative z-49 flex h-16 w-full flex-0 items-center px-4 shadow dark:border-b dark:bg-transparent dark:shadow-none md:px-6 print:hidden"
    >
        <!-- Navigation toggle button -->
        <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
        </button>
        <!-- Components -->
        <div class="ml-auto flex items-center space-x-0.5 pl-2 sm:space-x-2">
            <languages></languages>
            <wiki></wiki>
            @if (showAiChat) {
                <button
                    mat-icon-button
                    (click)="toggleAiChat()"
                    [matTooltip]="t('components.ai-chat.ai-chat-title', {botName: AI_CONSTANTS.BOT_NAME})">
                    <mat-icon [svgIcon]="'treebyte-ai-chat'"></mat-icon>
                </button>
            }
            <theme-picker></theme-picker>
            <fuse-fullscreen
                class="hidden md:block"
                tooltip="Fullscreen"
            ></fuse-fullscreen>
            <user></user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-auto flex-col" id="app-content">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
        Otherwise, layout changes won't be registered and the view won't be updated! -->
        @if (true) {
            <router-outlet></router-outlet>
        }
    </div>
</div>

<!-- Ai chat -->
@if (this.showAiChat) {
    <ai-chat #aiChat></ai-chat>
}
