import { ScrollStrategy, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { TextFieldModule } from '@angular/cdk/text-field';
import { DOCUMENT, DatePipe, NgClass, NgTemplateOutlet } from '@angular/common';
import {
    AfterViewInit,
    Component,
    DestroyRef,
    ElementRef,
    HostBinding,
    Inject,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild,
    ViewEncapsulation,
    inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltip } from '@angular/material/tooltip';
import { FuseScrollbarDirective } from '@fuse/directives/scrollbar';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { Subject, catchError, throwError } from 'rxjs';
import { AI_CONSTANTS } from './ai-chat.constants';
import { AiChatService } from './ai-chat.service';
import { AiChat } from './ai-chat.types';

@Component({
    selector: 'ai-chat',
    templateUrl: './ai-chat.component.html',
    styleUrls: ['./ai-chat.component.scss'],
    encapsulation: ViewEncapsulation.None,
    exportAs: 'aiChat',
    standalone: true,
    imports: [
        NgClass,
        MatIconModule,
        MatButtonModule,
        FuseScrollbarDirective,
        NgTemplateOutlet,
        MatFormFieldModule,
        MatInputModule,
        TextFieldModule,
        DatePipe,
        TranslocoDirective,
        MatTooltip,
    ],
})
export class AiChatComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('messageInput') messageInput: ElementRef;
    destroyRef = inject(DestroyRef);
    chat: AiChat;
    opened: boolean = false;
    private _mutationObserver: MutationObserver;
    private _scrollStrategy: ScrollStrategy =
        this._scrollStrategyOptions.block();
    private _overlay: HTMLElement;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    private markdownPatterns = [
        /```html([\s\S]*?)```/g, // Code block html ```code```
    ];

    /**
     * Constructor
     */
    constructor(
        @Inject(DOCUMENT) private _document: Document,
        private _elementRef: ElementRef,
        private _renderer2: Renderer2,
        private _quickChatService: AiChatService,
        private _scrollStrategyOptions: ScrollStrategyOptions,
        private transloco: TranslocoService
    ) {
        this.initChat();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Decorated methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Host binding for component classes
     */
    @HostBinding('class') get classList(): any {
        return {
            'ai-chat-opened': this.opened,
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {}

    /**
     * After view init
     */
    ngAfterViewInit(): void {
        // Fix for Firefox.
        //
        // Because 'position: sticky' doesn't work correctly inside a 'position: fixed' parent,
        // adding the '.cdk-global-scrollblock' to the html element breaks the navigation's position.
        // This fixes the problem by reading the 'top' value from the html element and adding it as a
        // 'marginTop' to the navigation itself.
        this._mutationObserver = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                const mutationTarget = mutation.target as HTMLElement;
                if (mutation.attributeName === 'class') {
                    if (
                        mutationTarget.classList.contains(
                            'cdk-global-scrollblock'
                        )
                    ) {
                        const top = parseInt(mutationTarget.style.top, 10);
                        this._renderer2.setStyle(
                            this._elementRef.nativeElement,
                            'margin-top',
                            `${Math.abs(top)}px`
                        );
                    } else {
                        this._renderer2.setStyle(
                            this._elementRef.nativeElement,
                            'margin-top',
                            null
                        );
                    }
                }
            });
        });
        this._mutationObserver.observe(this._document.documentElement, {
            attributes: true,
            attributeFilter: ['class'],
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Disconnect the mutation observer
        this._mutationObserver.disconnect();

        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Open the panel
     */
    open(): void {
        // Return if the panel has already opened
        if (this.opened) {
            return;
        }

        // Open the panel
        this._toggleOpened(true);
    }

    /**
     * Close the panel
     */
    close(): void {
        // Return if the panel has already closed
        if (!this.opened) {
            return;
        }

        // Close the panel
        this._toggleOpened(false);
    }

    /**
     * Toggle the panel
     */
    toggle(): void {
        if (this.opened) {
            this.close();
        } else {
            this.open();
        }
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Show the backdrop
     *
     * @private
     */
    private _showOverlay(): void {
        // Try hiding the overlay in case there is one already opened
        this._hideOverlay();

        // Create the backdrop element
        this._overlay = this._renderer2.createElement('div');

        // Return if overlay couldn't be created for some reason
        if (!this._overlay) {
            return;
        }

        // Add a class to the backdrop element
        this._overlay.classList.add('ai-chat-overlay');

        // Append the backdrop to the parent of the panel
        this._renderer2.appendChild(
            this._elementRef.nativeElement.parentElement,
            this._overlay
        );

        // Enable block scroll strategy
        this._scrollStrategy.enable();

        // Add an event listener to the overlay
        this._overlay.addEventListener('click', () => {
            this.close();
        });
    }

    /**
     * Hide the backdrop
     *
     * @private
     */
    private _hideOverlay(): void {
        if (!this._overlay) {
            return;
        }

        // If the backdrop still exists...
        if (this._overlay) {
            // Remove the backdrop
            this._overlay.parentNode.removeChild(this._overlay);
            this._overlay = null;
        }

        // Disable block scroll strategy
        this._scrollStrategy.disable();
    }

    /**
     * Open/close the panel
     *
     * @param open
     * @private
     */
    private _toggleOpened(open: boolean): void {
        // Set the opened
        this.opened = open;

        // If the panel opens, show the overlay
        if (open) {
            this._showOverlay();
        }
        // Otherwise, hide the overlay
        else {
            this._hideOverlay();
        }
    }

    chatWithAi($event: any, inputElem: HTMLTextAreaElement) {
        $event.preventDefault();

        if (!inputElem || !inputElem.value || !inputElem.value.trim()) {
            return;
        }

        let prompt = inputElem.value.trim();

        this.chat.messages.push({
            isMine: true,
            content: prompt,
        });

        this._quickChatService
            .chat({ chatId: this.chat.chatId, prompt: prompt })
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                catchError((err: any) => {
                    this.chat.errorOccurred = true;
                    let errorMessage = {
                        isMine: false,
                        content: this.transloco.translate(
                            'ai-chat.reset-chat-warning',
                            { error: err }
                        ),
                    };
                    this.chat.messages.push(errorMessage);
                    return throwError(() => err);
                })
            )
            .subscribe((res) => {
                this.chat.chatId ??= res.result.chatId;

                let newMessage = {
                    isMine: false,
                    content: this.stripMarkdownFromString(res.result.content),
                };

                this.chat.messages.push(newMessage);
            });

        inputElem.value = '';
    }

    initChat() {
        this.chat = {
            chatId: null,
            errorOccurred: false,
            messages: [
                {
                    isMine: false,
                    content: this.transloco.translate(
                        'components.ai-chat.ai-welcome-message',
                        { botName: AI_CONSTANTS.BOT_NAME }
                    ),
                },
            ],
        };
    }

    stripMarkdownFromString(stringToStrip: string) {
        // Apply all patterns and remove matched Markdown tags
        let plainText = stringToStrip;
        this.markdownPatterns.forEach((pattern) => {
            plainText = plainText.replace(pattern, '$1');
        });

        return plainText;
    }

    protected readonly AI_CONSTANTS = AI_CONSTANTS;
}
