import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { BaseService } from '../../../core/services/base.service';
import { ApiResult } from '../../../shared/types/api-result.types';
import { AiChatResponse } from './ai-chat.types';

@Injectable({ providedIn: 'root' })
export class AiChatService extends BaseService {
    protected apiUrl = environment.apiUrl;

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
        super();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    chat(payload: {
        chatId: string;
        prompt: string;
    }): Observable<ApiResult<AiChatResponse>> {
        return this._httpClient
            .post<
                ApiResult<AiChatResponse>
            >(`${this.apiUrl}backoffice/ai/chat/`, payload)
            .pipe(catchError(this.handleError));
    }
}
