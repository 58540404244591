<ng-container *transloco="let t">
    <!-- Button -->
    <button mat-icon-button [matMenuTriggerFor]="userActions">
        @if (showAvatar && user.avatar) {
            <img
                alt="user avatar"
                class="h-7 w-7 rounded-full"
                [src]="user.avatar"
            />
        }
        @if (!showAvatar || !user.avatar) {
            <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        }
    </button>

    <mat-menu [xPosition]="'before'" #userActions="matMenu">
        <button mat-menu-item>
            <span class="flex flex-col py-2 leading-none">
                <span>{{ t('user.signed-as') }}</span>
                @if (user?.fullName) {
                    <span class="mt-1.5 text-md font-medium"
                        >{{ user?.fullName }}
                        @if (user?.username) {
                            <span class="ml-1.5">({{ user?.username }})</span>
                        }
                    </span>
                }
                <span class="mt-1.5 text-md font-light">{{ user?.email }}</span>
            </span>
        </button>

        <!--hiding until implementation -->
        <mat-divider class="my-2"></mat-divider>
        <button mat-menu-item (click)="openProfilePage()">
            <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
            <span>{{ t('user.profile') }}</span>
        </button>
        <!--    <button mat-menu-item>-->
        <!--        <mat-icon [svgIcon]="'heroicons_outline:cog-8-tooth'"></mat-icon>-->
        <!--        <span>{{t('user.settings')}}</span>-->
        <!--    </button>-->
        <mat-divider class="my-2"></mat-divider>
        <button mat-menu-item (click)="signOut()">
            <mat-icon
                [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"
            ></mat-icon>
            <span>{{ t('user.sign-out') }}</span>
        </button>
    </mat-menu>
</ng-container>
