import { inject } from '@angular/core';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { forkJoin } from 'rxjs';
import { LicenseService } from './modules/backoffice/license/license.service';

export const initialDataResolver = () => {
    const navigationService = inject(NavigationService);
    const licenseService = inject(LicenseService);

    // Fork join multiple API endpoint calls to wait all of them to finish
    return forkJoin([
        navigationService.get(),
        licenseService.refreshLicenseInfo(),
    ]);
};
