import { CanMatchFn, Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { environment } from '../environments/environment';
import { initialDataResolver } from './app.resolvers';
import { SsPermissionsClaims } from './core/auth/auth.types';
import { ModuleGuard } from './core/auth/guards/module.guard';
import { PermissionGuard } from './core/auth/guards/permission.guard';
import { SS_SUPPORTED_MODULES } from './modules/backoffice/license/modules/modules.constants';
import { ResourceNotFoundComponent } from './modules/errors/resource-not-found/resource-not-found.component';

const canMatchDevelopmentEnv: CanMatchFn = () => {
    return !environment.production;
};

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    // Redirect empty path to main page
    { path: '', pathMatch: 'full', redirectTo: 'home' },

    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'home' },

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'confirmation-required',
                loadChildren: () =>
                    import(
                        'app/modules/auth/confirmation-required/confirmation-required.routes'
                    ),
            },
            {
                path: 'forgot-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/forgot-password/forgot-password.routes'
                    ),
            },
            {
                path: 'reset-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/reset-password/reset-password.routes'
                    ),
            },
            {
                path: 'sign-in',
                loadChildren: () =>
                    import('app/modules/auth/sign-in/sign-in.routes'),
            },
        ],
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'sign-out',
                loadChildren: () =>
                    import('app/modules/auth/sign-out/sign-out.routes'),
            },
            {
                path: 'forbidden',
                loadChildren: () =>
                    import('./modules/auth/forbidden/forbidden.routes'),
            },
        ],
    },

    // Application routes
    {
        // Do not use a resolver to fetch data needed by a guard.
        // The execution order is: guards first, resolvers last.
        // This can cause issues with child guards that depend on `initialData`.
        //
        // Example issue:
        // The `ModuleGuard` requires license data to verify if modules are enabled.
        // However, when using a resolver, the execution order is:
        // `AuthGuard` → `ModuleGuard` → `initialDataResolver`.
        // This results in `ModuleGuard` running before the data is available, leading to an unauthorized response.
        //
        // Solution:
        // To avoid this issue, fetch license data inside provideAppInitializer.
        // More details: https://v17.angular.io/api/router/Resolve#usage-notes
        path: '',
        canActivate: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver,
        },
        children: [
            {
                path: 'accounts',
                loadChildren: () =>
                    import('app/modules/backoffice/accounts/accounts.routes'),
                canActivate: [PermissionGuard],
                data: { permission: SsPermissionsClaims.Users },
            },
            {
                path: 'home',
                loadChildren: () =>
                    import('app/modules/backoffice/home/home.routes'),
            },
            {
                path: 'configuration',
                loadChildren: () =>
                    import(
                        'app/modules/backoffice/configuration/configuration.routes'
                    ),
                canActivate: [PermissionGuard],
                data: { permission: SsPermissionsClaims.Configuration },
            },
            {
                path: 'meas-units',
                loadChildren: () =>
                    import(
                        'app/modules/backoffice/meas-units/meas-units.routes'
                    ),
                canActivate: [PermissionGuard],
                data: { permission: SsPermissionsClaims.MeasUnits },
            },
            {
                path: 'vats',
                loadChildren: () =>
                    import('app/modules/backoffice/vats/vats.routes'),
                canActivate: [PermissionGuard],
                data: { permission: SsPermissionsClaims.Vats },
            },
            {
                path: 'currencies',
                loadChildren: () =>
                    import(
                        'app/modules/backoffice/currencies/currencies.routes'
                    ),
                canActivate: [PermissionGuard],
                data: { permission: SsPermissionsClaims.Currencies },
            },
            {
                path: 'countries',
                loadChildren: () =>
                    import('app/modules/backoffice/countries/countries.routes'),
                canActivate: [PermissionGuard],
                data: { permission: SsPermissionsClaims.Countries },
            },
            {
                path: 'kiosk/layouts',
                loadChildren: () =>
                    import(
                        'app/modules/backoffice/kiosk/layouts/layouts.routes'
                    ),
                canActivate: [ModuleGuard, PermissionGuard],
                data: {
                    moduleName: SS_SUPPORTED_MODULES.KIOSK,
                    permission: SsPermissionsClaims.KioskApplicationData,
                },
            },
            {
                path: 'kiosk/ui-groups',
                loadChildren: () =>
                    import(
                        'app/modules/backoffice/kiosk/ui-groups/ui-groups.routes'
                    ),
                canActivate: [ModuleGuard, PermissionGuard],
                data: {
                    moduleName: SS_SUPPORTED_MODULES.KIOSK,
                    permission: SsPermissionsClaims.KioskApplicationData,
                },
            },
            {
                path: 'kiosk/tile-templates',
                loadChildren: () =>
                    import(
                        'app/modules/backoffice/kiosk/tile-templates/tile-templates.routes'
                    ),
                canActivate: [ModuleGuard, PermissionGuard],
                data: {
                    moduleName: SS_SUPPORTED_MODULES.KIOSK,
                    permission: SsPermissionsClaims.KioskApplicationData,
                },
            },
            {
                path: 'kiosk/layouts-schedule',
                loadChildren: () =>
                    import(
                        'app/modules/backoffice/kiosk/layouts-schedule/layouts-schedule.routes'
                    ),
                canActivate: [ModuleGuard, PermissionGuard],
                data: {
                    moduleName: SS_SUPPORTED_MODULES.KIOSK,
                    permission: SsPermissionsClaims.KioskApplicationData,
                },
            },
            {
                path: 'kiosk/media-schedule',
                loadChildren: () =>
                    import(
                        'app/modules/backoffice/kiosk/media-schedule/media-schedule.routes'
                    ),
                canActivate: [ModuleGuard, PermissionGuard],
                data: {
                    moduleName: SS_SUPPORTED_MODULES.KIOSK,
                    permission: SsPermissionsClaims.KioskApplicationData,
                },
            },
            {
                path: 'fidelity/surveys',
                loadChildren: () =>
                    import(
                        'app/modules/backoffice/fidelity/surveys/surveys.routes'
                    ),
                canActivate: [ModuleGuard, PermissionGuard],
                data: {
                    moduleName: SS_SUPPORTED_MODULES.FIDELITY,
                    permission: SsPermissionsClaims.FidelityApplicationData,
                },
            },
            {
                path: 'fidelity/marketing-contents',
                loadChildren: () =>
                    import(
                        'app/modules/backoffice/fidelity/marketing-contents/marketing-contents.routes'
                    ),
                canActivate: [ModuleGuard, PermissionGuard],
                data: {
                    moduleName: SS_SUPPORTED_MODULES.FIDELITY,
                    permission: SsPermissionsClaims.FidelityApplicationData,
                },
            },
            {
                path: 'pos',
                loadChildren: () =>
                    import('app/modules/backoffice/pos/pos.routes'),
                canActivate: [PermissionGuard],
                data: { permission: SsPermissionsClaims.Pos },
            },
            {
                path: 'roles',
                loadChildren: () =>
                    import('app/modules/backoffice/roles/roles.routes'),
                canActivate: [PermissionGuard],
                data: { permission: SsPermissionsClaims.Roles },
            },
            {
                path: 'companies',
                loadChildren: () =>
                    import('app/modules/backoffice/companies/companies.routes'),
                canActivate: [PermissionGuard],
                data: { permission: SsPermissionsClaims.Companies },
            },
            {
                path: 'shops',
                loadChildren: () =>
                    import('app/modules/backoffice/shops/shops.routes'),
                canActivate: [PermissionGuard],
                data: { permission: SsPermissionsClaims.Shops },
            },
            {
                path: 'articles',
                loadChildren: () =>
                    import('app/modules/backoffice/articles/articles.routes'),
                canActivate: [PermissionGuard],
                data: { permission: SsPermissionsClaims.Articles },
            },
            {
                path: 'recipes',
                loadChildren: () =>
                    import('app/modules/backoffice/recipes/recipes.routes'),
                canActivate: [PermissionGuard],
                data: { permission: SsPermissionsClaims.Recipes },
            },
            {
                path: 'upsells',
                loadChildren: () =>
                    import('app/modules/backoffice/upsells/upsells.routes'),
                canActivate: [PermissionGuard],
                data: { permission: SsPermissionsClaims.Upsells },
            },
            {
                path: 'sale-price-lists',
                loadChildren: () =>
                    import(
                        'app/modules/backoffice/sale-price-lists/sale-price-lists.routes'
                    ),
                canActivate: [PermissionGuard],
                data: { permission: SsPermissionsClaims.SalePriceLists },
            },
            {
                path: 'tags',
                loadChildren: () =>
                    import('app/modules/backoffice/tags/tags.routes'),
                canActivate: [PermissionGuard],
                data: { permission: SsPermissionsClaims.Tags },
            },
            {
                path: 'out-of-stock',
                loadChildren: () =>
                    import(
                        'app/modules/backoffice/out-of-stock/out-of-stock.routes'
                    ),
                canActivate: [PermissionGuard],
                data: { permission: SsPermissionsClaims.OutOfStock },
            },
            {
                path: 'customers',
                loadChildren: () =>
                    import('app/modules/backoffice/customers/customers.routes'),
                canActivate: [PermissionGuard],
                data: { permission: SsPermissionsClaims.Customers },
            },
            {
                path: 'customer-card-types',
                loadChildren: () =>
                    import(
                        'app/modules/backoffice/customers/customer-card-types/customer-card-types.routes'
                    ),
                canActivate: [PermissionGuard],
                data: { permission: SsPermissionsClaims.Customers },
            },
            {
                path: 'profile',
                loadChildren: () =>
                    import(
                        'app/modules/backoffice/user-profile/user-profile.routes'
                    ),
            },
            {
                path: 'development',
                loadChildren: () =>
                    import('app/modules/development/sandbox/sandbox.routes'),
                canMatch: [canMatchDevelopmentEnv],
            },
        ],
    },

    { path: 'not-found', component: ResourceNotFoundComponent },
    //wildcard route for inaccessible resources.
    { path: '**', component: ResourceNotFoundComponent },
];
