import { NgOptimizedImage } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
    selector: 'app-resource-not-found-component',
    standalone: true,
    imports: [RouterLink, TranslocoDirective, NgOptimizedImage],
    templateUrl: './resource-not-found.component.html',
    styleUrl: './resource-not-found.component.scss',
})
export class ResourceNotFoundComponent {}
