import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { fuseAnimations } from '@fuse/animations';
import { AlertService } from './alert.service';
import { Alert, AlertType } from './alert.types';

@Component({
    selector: 'alert',
    templateUrl: 'alert.component.html',
    animations: fuseAnimations,
    imports: [],
    standalone: true,
})
export class AlertComponent implements OnInit, OnDestroy {
    @Input() id = 'default-alert';
    @Input() fade = true;

    alerts: Alert[] = [];
    alertSubscription: Subscription;
    routeSubscription: Subscription;

    constructor(
        private router: Router,
        private alertService: AlertService
    ) {}

    ngOnInit() {
        // subscribe to new alert notifications
        this.alertSubscription = this.alertService
            .onAlert(this.id)
            .subscribe((alert) => {
                // clear alerts when an empty alert is received
                if (!alert.message) {
                    // filter out alerts without 'keepAfterRouteChange' flag
                    this.alerts = this.alerts.filter(
                        (x) => x.keepAfterRouteChange
                    );

                    // remove 'keepAfterRouteChange' flag on the rest
                    this.alerts.forEach((x) => delete x.keepAfterRouteChange);
                    return;
                }

                // add alert to array
                this.alerts.push(alert);

                // auto close alert if required
                if (alert.autoClose) {
                    setTimeout(() => this.removeAlert(alert), 6000);
                }
            });

        // clear alerts on location change
        this.routeSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                this.alertService.clear(this.id);
            }
        });
    }

    ngOnDestroy() {
        // unsubscribe to avoid memory leaks
        this.alertSubscription.unsubscribe();
        this.routeSubscription.unsubscribe();
    }

    removeAlert(alert: Alert) {
        // check if already removed to prevent error on auto close
        if (!this.alerts.includes(alert)) return;

        if (this.fade) {
            alert.fadeState = '*';

            // fade out alert
            this.alerts.find((x) => x === alert).fade = true;

            // remove alert after faded out
            setTimeout(() => {
                this.alerts = this.alerts.filter((x) => x !== alert);
            }, 250);
        } else {
            // remove alert
            this.alerts = this.alerts.filter((x) => x !== alert);
        }
    }

    cssClass(alert: Alert) {
        if (!alert) return;

        const classes = [];

        const alertTypeClass = {
            [AlertType.Success]: 'bg-emerald-500 text-white',
            [AlertType.Error]: 'bg-red-500 text-white',
            [AlertType.Info]: 'bg-blue-500 text-white',
            [AlertType.Warning]: 'bg-yellow-500 text-black',
        };

        classes.push(alertTypeClass[alert.type]);

        if (alert.fade) {
            alert.fadeState = 'void';
        }

        return classes.join(' ');
    }
}
