<div class="absolute inset-0" *transloco="let t">
    <div
        class="flex h-full flex-auto flex-col items-center justify-center p-6 sm:p-10"
    >
        <img
            alt="treebyte decal"
            priority="1"
            ngSrc="/images/ui/sign-in/login-treebyte-1.svg"
            class="invisible absolute right-0 top-0 w-36 lg:visible"
            height="16"
            width="16"
        />
        <img
            alt="treebyte decal 2"
            ngSrc="/images/ui/sign-in/login-treebyte-2.png"
            class="invisible absolute bottom-0 left-0 w-48 opacity-40 lg:visible"
            height="329"
            width="309"
        />

        <div
            class="mt-12 text-center text-4xl font-extrabold leading-tight tracking-tight sm:mt-24 md:text-7xl md:leading-none"
        >
            {{ t('not-found.title') }}
        </div>
        <div
            class="mt-2 text-center text-lg font-medium tracking-tight text-primary dark:text-white md:text-xl"
        >
            {{ t('not-found.desc') }}
        </div>
        <a
            class="mt-12 font-medium text-primary underline dark:text-white"
            [routerLink]="['']"
        >
            {{ t('not-found.go-home') }}
        </a>
    </div>
</div>
