import { Component, ErrorHandler } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { loadMessages, locale } from 'devextreme/localization';

/*
    !!! DO NOT REMOVE THE IMPORT BELOW !!!
    In order to track uncaught exceptions using ErrorService, it is required to import the specified extension
    https://learn.microsoft.com/it-it/azure/azure-monitor/app/javascript-framework-extensions?tabs=angular#track-exceptions
*/
import { DefaultValueAccessor } from '@angular/forms';
import '@microsoft/applicationinsights-analytics-js';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import config from 'devextreme/core/config';
import dxDataGrid from 'devextreme/ui/data_grid';
import itMessages from '../../public/devexpress/it.json';
import { licenseKey } from '../devextreme-license';
import { AlertComponent } from './core/alert/alert.component';
import { MonitoringService } from './core/services/monitoring.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet, AlertComponent],
    providers: [
        {
            provide: ErrorHandler,
            useClass: ApplicationinsightsAngularpluginErrorService,
        },
    ],
})
export class AppComponent {
    /**
     * Constructor
     */
    constructor(
        private transloco: TranslocoService,
        private monitoringService: MonitoringService
    ) {
        this.initializeDevexpress();

        this.CustomizeValueAccessorsBehaviour();
    }

    /**
     * Sets up custom behaviour for all value accessors (used on inputs)
     * - empty or all spaces strings are converted to null values
     * - values are trimmed
     * @constructor
     */
    CustomizeValueAccessorsBehaviour() {
        DefaultValueAccessor.prototype.registerOnChange = function (
            fn: (_: string | null) => void
        ): void {
            this.onChange = (value: string | null) => {
                fn(value?.trim() === '' ? null : value.trim());
            };
        };
    }

    initializeDevexpress() {
        config({ licenseKey });
        loadMessages(itMessages);
        locale(this.transloco.getActiveLang());
        dxDataGrid.defaultOptions({
            options: {
                scrolling: {
                    useNative: true, //Allows to scroll using the mouse wheel or sliding the thumb on a touchscreen
                },
                editing: {
                    texts: {
                        confirmDeleteMessage: '', //Removes the delete confirmation popup
                    },
                },
            },
        });
    }
}
