{
    "it": {
        "Yes": "Sì",
        "No": "No",
        "Cancel": "Annulla",
        "Close": "Chiudi",
        "Clear": "Cancella",
        "Done": "Fatto",
        "Loading": "Caricamento...",
        "Select": "Seleziona...",
        "Search": "Cerca",
        "Back": "Indietro",
        "OK": "OK",

        "dxCollectionWidget-noDataText": "Nessun dato da mostrare",

        "dxDropDownEditor-selectLabel": "Seleziona",

        "validation-required": "Richiesto",
        "validation-required-formatted": "{0} è richiesto",
        "validation-numeric": "Il valore deve essere numerico",
        "validation-numeric-formatted": "{0} deve essere numerico",
        "validation-range": "Il valore non è compreso nell'intervallo",
        "validation-range-formatted": "{0} non è compreso nell'intervallo",
        "validation-stringLength": "Lunghezza del valore errata",
        "validation-stringLength-formatted": "La lunghezza di {0} è errata",
        "validation-custom": "Il valore non è corretto",
        "validation-custom-formatted": "{0} non è corretto",
        "validation-async": "Il valore non è corretto",
        "validation-async-formatted": "{0} non è corretto",
        "validation-compare": "I valori non corrispondono",
        "validation-compare-formatted": "{0} non corrisponde",
        "validation-pattern": "Il valore non rispetta il formato previsto",
        "validation-pattern-formatted": "{0} non rispetta il formato previsto",
        "validation-email": "L'email non è corretta",
        "validation-email-formatted": "{0} non è una email corretta",
        "validation-mask": "Il valore non è corretto",

        "dxLookup-searchPlaceholder": "Lunghezza minima: {0}",

        "dxList-pullingDownText": "Trascina in basso per aggiornare...",
        "dxList-pulledDownText": "Rilascia per aggiornare...",
        "dxList-refreshingText": "Aggiornamento...",
        "dxList-pageLoadingText": "Caricamento...",
        "dxList-nextButtonText": "Carica altri risultati",
        "dxList-selectAll": "Seleziona tutti",
        "dxListEditDecorator-delete": "Elimina",
        "dxListEditDecorator-more": "Ancora",

        "dxScrollView-pullingDownText": "Trascina in basso per aggiornare...",
        "dxScrollView-pulledDownText": "Rilascia per aggiornare...",
        "dxScrollView-refreshingText": "Aggiornamento...",
        "dxScrollView-reachBottomText": "Caricamento...",

        "dxDateBox-simulatedDataPickerTitleTime": "Seleziona orario",
        "dxDateBox-simulatedDataPickerTitleDate": "Seleziona data",
        "dxDateBox-simulatedDataPickerTitleDateTime": "Seleziona data e ora",
        "dxDateBox-validation-datetime": "Il valore deve essere una data o un orario",

        "dxDateRangeBox-invalidStartDateMessage": "Start value must be a date",
        "dxDateRangeBox-invalidEndDateMessage": "End value must be a date",
        "dxDateRangeBox-startDateOutOfRangeMessage": "Start date is out of range",
        "dxDateRangeBox-endDateOutOfRangeMessage": "End date is out of range",
        "dxDateRangeBox-startDateLabel": "Data inizio",
        "dxDateRangeBox-endDateLabel": "Data fine",

        "dxFileUploader-selectFile": "Seleziona file",
        "dxFileUploader-dropFile": "o trascina il file qui",
        "dxFileUploader-bytes": "bytes",
        "dxFileUploader-kb": "KB",
        "dxFileUploader-Mb": "MB",
        "dxFileUploader-Gb": "GB",
        "dxFileUploader-upload": "Carica",
        "dxFileUploader-uploaded": "Caricato",
        "dxFileUploader-readyToUpload": "Pronto per caricare",
        "dxFileUploader-uploadAbortedMessage": "Caricamento annullato",
        "dxFileUploader-uploadFailedMessage": "Caricamento fallito",
        "dxFileUploader-invalidFileExtension": "Il tipo di file non è consentito",
        "dxFileUploader-invalidMaxFileSize": "File troppo grande",
        "dxFileUploader-invalidMinFileSize": "File troppo piccolo",

        "dxRangeSlider-ariaFrom": "Da",
        "dxRangeSlider-ariaTill": "A",
        "dxSwitch-switchedOnText": "ON",
        "dxSwitch-switchedOffText": "OFF",

        "dxForm-optionalMark": "opzionale",
        "dxForm-requiredMessage": "{0} è richiesto",

        "dxNumberBox-invalidValueMessage": "Il valore deve essere numerico",
        "dxNumberBox-noDataText": "Nessun dato",

        "dxDataGrid-emptyHeaderWithColumnChooserText": "Use {0} to display columns",
        "dxDataGrid-emptyHeaderWithGroupPanelText": "Drag a column from the group panel here",
        "dxDataGrid-emptyHeaderWithColumnChooserAndGroupPanelText": "Use {0} or drag a column from the group panel",
        "dxDataGrid-emptyHeaderColumnChooserText": "column chooser",
        "dxDataGrid-columnChooserTitle": "Selezione colonne",
        "dxDataGrid-columnChooserEmptyText": "Trascina qui una colonna per nasconderla",
        "dxDataGrid-groupContinuesMessage": "Continua alla pagina successiva",
        "dxDataGrid-groupContinuedMessage": "Continua dalla pagina precedente",
        "dxDataGrid-groupHeaderText": "Raggruppa per questa colonna",
        "dxDataGrid-ungroupHeaderText": "Separa",
        "dxDataGrid-ungroupAllText": "Separa tutti",
        "dxDataGrid-editingEditRow": "Modifica",
        "dxDataGrid-editingSaveRowChanges": "Salva",
        "dxDataGrid-editingCancelRowChanges": "Annulla",
        "dxDataGrid-editingDeleteRow": "Elimina",
        "dxDataGrid-editingUndeleteRow": "Ripristina",
        "dxDataGrid-editingConfirmDeleteMessage": "Sei certo di voler eliminare questo record?",
        "dxDataGrid-validationCancelChanges": "Annulla le modifiche",
        "dxDataGrid-groupPanelEmptyText": "Trascina qui l'intestazione di una colonna per raggrupparla",
        "dxDataGrid-noDataText": "Nessun dato",
        "dxDataGrid-searchPanelPlaceholder": "Cerca...",
        "dxDataGrid-filterRowShowAllText": "(Tutti)",
        "dxDataGrid-filterRowResetOperationText": "Annulla",
        "dxDataGrid-filterRowOperationEquals": "Uguale",
        "dxDataGrid-filterRowOperationNotEquals": "Diverso",
        "dxDataGrid-filterRowOperationLess": "Minore di",
        "dxDataGrid-filterRowOperationLessOrEquals": "Minore o uguale a",
        "dxDataGrid-filterRowOperationGreater": "Maggiore di",
        "dxDataGrid-filterRowOperationGreaterOrEquals": "Maggiore o uguale a",
        "dxDataGrid-filterRowOperationStartsWith": "Inizia con",
        "dxDataGrid-filterRowOperationContains": "Contiene",
        "dxDataGrid-filterRowOperationNotContains": "Non contiene",
        "dxDataGrid-filterRowOperationEndsWith": "Termina con",
        "dxDataGrid-filterRowOperationBetween": "Compreso",
        "dxDataGrid-filterRowOperationBetweenStartText": "Inizio",
        "dxDataGrid-filterRowOperationBetweenEndText": "Fine",
        "dxDataGrid-ariaSearchBox": "Search box",
        "dxDataGrid-applyFilterText": "Applica filtro",
        "dxDataGrid-trueText": "vero",
        "dxDataGrid-falseText": "falso",
        "dxDataGrid-sortingAscendingText": "Ordinamento ascendente",
        "dxDataGrid-sortingDescendingText": "Ordinamento discendente",
        "dxDataGrid-sortingClearText": "Annulla ordinamento",
        "dxDataGrid-ariaNotSortedColumn": "Not sorted column",
        "dxDataGrid-ariaSortedAscendingColumn": "Column sorted in ascending order",
        "dxDataGrid-ariaSortedDescendingColumn": "Column sorted in descending order",
        "dxDataGrid-ariaSortIndex": "Sort index {0}",
        "dxDataGrid-editingSaveAllChanges": "Salva le modifiche",
        "dxDataGrid-editingCancelAllChanges": "Annulla le modifiche",
        "dxDataGrid-editingAddRow": "Aggiungi una riga",
        "dxDataGrid-summaryMin": "Min: {0}",
        "dxDataGrid-summaryMinOtherColumn": "Min di {1} è {0}",
        "dxDataGrid-summaryMax": "Max: {0}",
        "dxDataGrid-summaryMaxOtherColumn": "Max di {1} è {0}",
        "dxDataGrid-summaryAvg": "Media: {0}",
        "dxDataGrid-summaryAvgOtherColumn": "Media di {1} è {0}",
        "dxDataGrid-summarySum": "Somma: {0}",
        "dxDataGrid-summarySumOtherColumn": "Somma di {1} è {0}",
        "dxDataGrid-summaryCount": "Conteggio: {0}",
        "dxDataGrid-columnFixingFix": "Blocca",
        "dxDataGrid-columnFixingUnfix": "Sblocca",
        "dxDataGrid-columnFixingLeftPosition": "Alla sinistra",
        "dxDataGrid-columnFixingRightPosition": "Alla destra",
        "dxDataGrid-exportTo": "Esporta",
        "dxDataGrid-exportToExcel": "Esporta in Excel",
        "dxDataGrid-exporting": "Esportazione...",
        "dxDataGrid-excelFormat": "File Excel",
        "dxDataGrid-selectedRows": "Righe selezionate",
        "dxDataGrid-exportSelectedRows": "Esporta le righe selezionate in {0}",
        "dxDataGrid-exportAll": "Esporta tutti i dati in {0}",
        "dxDataGrid-headerFilterLabel": "Filter options",
        "dxDataGrid-headerFilterIndicatorLabel": "Show filter options for column '{0}'",
        "dxDataGrid-headerFilterEmptyValue": "(vuoto)",
        "dxDataGrid-headerFilterOK": "OK",
        "dxDataGrid-headerFilterCancel": "Annulla",
        "dxDataGrid-ariaAdaptiveCollapse": "Nascondi ulteriori dati",
        "dxDataGrid-ariaAdaptiveExpand": "Mostra ulteriori dati",
        "dxDataGrid-ariaColumn": "Colonna",
        "dxDataGrid-ariaColumnHeader": "Column header",
        "dxDataGrid-ariaValue": "Valore",
        "dxDataGrid-ariaError": "Error",
        "dxDataGrid-ariaRevertButton": "Press Escape to discard the changes",
        "dxDataGrid-ariaFilterCell": "Filtra cella",
        "dxDataGrid-ariaCollapse": "Comprimi",
        "dxDataGrid-ariaModifiedCell": "Modified",
        "dxDataGrid-ariaDeletedCell": "Deleted",
        "dxDataGrid-ariaEditableCell": "Editable",
        "dxDataGrid-ariaExpand": "Espandi",
        "dxDataGrid-ariaCollapsedRow": "Collapsed row",
        "dxDataGrid-ariaExpandedRow": "Expanded row",
        "dxDataGrid-ariaDataGrid": "Griglia dati",
        "dxDataGrid-ariaSearchInGrid": "Cerca nella griglia",
        "dxDataGrid-ariaSelectAll": "Seleziona tutti",
        "dxDataGrid-ariaSelectRow": "Seleziona riga",
        "dxDataGrid-ariaToolbar": "Barra degli strumenti griglia",
        "dxDataGrid-ariaEditForm": "Edit form",
        "dxDataGrid-filterBuilderPopupTitle": "Composizione filtro",
        "dxDataGrid-filterPanelCreateFilter": "Nuovo filtro",
        "dxDataGrid-filterPanelClearFilter": "Cancella",
        "dxDataGrid-filterPanelFilterEnabledHint": "Attiva il filtro",

        "dxTreeList-ariaTreeList": "Tree list with {0} rows and {1} columns",
        "dxTreeList-ariaSearchInGrid": "Ricerca nell'albero",
        "dxTreeList-ariaToolbar": "Barra degli strumenti albero",
        "dxTreeList-editingAddRowToNode": "Aggiungi",

        "dxPager-infoText": "Pagina {0} di {1} ({2} elementi)",
        "dxPager-pagesCountText": "di",
        "dxPager-pageSize": "Items per page: {0}",
        "dxPager-pageSizesAllText": "Tutti",
        "dxPager-page": "Pagina {0}",
        "dxPager-prevPage": "Pagina precedente",
        "dxPager-nextPage": "Pagina successiva",
        "dxPager-ariaLabel": "Navigazione pagine",
        "dxPager-ariaPageSize": "Page size",
        "dxPager-ariaPageNumber": "Page number",

        "dxPivotGrid-grandTotal": "Totale",
        "dxPivotGrid-total": "{0} Totale",
        "dxPivotGrid-fieldChooserTitle": "Selezione campi",
        "dxPivotGrid-showFieldChooser": "Mostra selezione campi",
        "dxPivotGrid-expandAll": "Espandi tutto",
        "dxPivotGrid-collapseAll": "Comprimi tutto",
        "dxPivotGrid-sortColumnBySummary": "Ordina \"{0}\" per questa colonna",
        "dxPivotGrid-sortRowBySummary": "Ordina \"{0}\" per questa riga",
        "dxPivotGrid-removeAllSorting": "Rimuovi ordinamenti",
        "dxPivotGrid-dataNotAvailable": "N/D",
        "dxPivotGrid-rowFields": "Campi riga",
        "dxPivotGrid-columnFields": "Campi colonna",
        "dxPivotGrid-dataFields": "Campi dati",
        "dxPivotGrid-filterFields": "Campi filtro",
        "dxPivotGrid-allFields": "Tutti i campi",
        "dxPivotGrid-columnFieldArea": "Trascina qui i campi colonna",
        "dxPivotGrid-dataFieldArea": "Trascina qui i campi dati",
        "dxPivotGrid-rowFieldArea": "Trascina qui i campi riga",
        "dxPivotGrid-filterFieldArea": "Trascina qui i campi filtro",

        "dxScheduler-editorLabelTitle": "Oggetto",
        "dxScheduler-editorLabelStartDate": "Data inizio",
        "dxScheduler-editorLabelEndDate": "Data fine",
        "dxScheduler-editorLabelDescription": "Descrizione",
        "dxScheduler-editorLabelRecurrence": "Ripeti",

        "dxScheduler-openAppointment": "Apri appuntamento",

        "dxScheduler-recurrenceNever": "Mai",
        "dxScheduler-recurrenceMinutely": "Ogni minuto",
        "dxScheduler-recurrenceHourly": "Ogni ora",
        "dxScheduler-recurrenceDaily": "Giornaliero",
        "dxScheduler-recurrenceWeekly": "Settimanale",
        "dxScheduler-recurrenceMonthly": "Mensile",
        "dxScheduler-recurrenceYearly": "Annuale",

        "dxScheduler-recurrenceRepeatEvery": "Ogni",
        "dxScheduler-recurrenceRepeatOn": "Ripeti ogni",
        "dxScheduler-recurrenceEnd": "Termina ripetizione",
        "dxScheduler-recurrenceAfter": "Dopo",
        "dxScheduler-recurrenceOn": "Di",

        "dxScheduler-recurrenceRepeatMinutely": "minuto(u)",
        "dxScheduler-recurrenceRepeatHourly": "ora(e)",
        "dxScheduler-recurrenceRepeatDaily": "giorno(i)",
        "dxScheduler-recurrenceRepeatWeekly": "settimana(e)",
        "dxScheduler-recurrenceRepeatMonthly": "mese(i)",
        "dxScheduler-recurrenceRepeatYearly": "anno(i)",

        "dxScheduler-switcherDay": "Giorno",
        "dxScheduler-switcherWeek": "Settimana",
        "dxScheduler-switcherWorkWeek": "Settimana lavorativa",
        "dxScheduler-switcherMonth": "Mese",

        "dxScheduler-switcherAgenda": "Agenda",

        "dxScheduler-switcherTimelineDay": "Cronologia giornaliera",
        "dxScheduler-switcherTimelineWeek": "Cronologia settimanale",
        "dxScheduler-switcherTimelineWorkWeek": "Cronologia settimana lavorativa",
        "dxScheduler-switcherTimelineMonth": "Cronologia mensile",

        "dxScheduler-recurrenceRepeatOnDate": "alla data",
        "dxScheduler-recurrenceRepeatCount": "occorrenza(e)",
        "dxScheduler-allDay": "Tutto il giorno",

        "dxScheduler-confirmRecurrenceEditTitle": "Edit Recurring Appointment",
        "dxScheduler-confirmRecurrenceDeleteTitle": "Delete Recurring Appointment",

        "dxScheduler-confirmRecurrenceEditMessage": "Vuoi modificare solo questo appuntamento o tutte le sue ricorrenze?",
        "dxScheduler-confirmRecurrenceDeleteMessage": "Vuoi eliminare solo questo appuntamento o tutte le sue ricorrenze?",

        "dxScheduler-confirmRecurrenceEditSeries": "Modifica serie",
        "dxScheduler-confirmRecurrenceDeleteSeries": "Elimina serie",
        "dxScheduler-confirmRecurrenceEditOccurrence": "Modifica appuntamento",
        "dxScheduler-confirmRecurrenceDeleteOccurrence": "Elimina appuntamento",

        "dxScheduler-noTimezoneTitle": "Nessun fuso orario",
        "dxScheduler-moreAppointments": "{0} ancora",

        "dxCalendar-todayButtonText": "Oggi",
        "dxCalendar-ariaWidgetName": "Calendario",
        "dxCalendar-previousMonthButtonLabel": "Previous month",
        "dxCalendar-previousYearButtonLabel": "Previous year",
        "dxCalendar-previousDecadeButtonLabel": "Previous decade",
        "dxCalendar-previousCenturyButtonLabel": "Previous century",
        "dxCalendar-nextMonthButtonLabel": "Next month",
        "dxCalendar-nextYearButtonLabel": "Next year",
        "dxCalendar-nextDecadeButtonLabel": "Next decade",
        "dxCalendar-nextCenturyButtonLabel": "Next century",
        "dxCalendar-captionMonthLabel": "Month selection",
        "dxCalendar-captionYearLabel": "Year selection",
        "dxCalendar-captionDecadeLabel": "Decade selection",
        "dxCalendar-captionCenturyLabel": "Century selection",

        "dxColorView-ariaRed": "Rosso",
        "dxColorView-ariaGreen": "Verde",
        "dxColorView-ariaBlue": "Blu",
        "dxColorView-ariaAlpha": "Trasparenza",
        "dxColorView-ariaHex": "Colore",

        "dxTagBox-selected": "{0} selezionati",
        "dxTagBox-allSelected": "Tutti selezionati ({0})",
        "dxTagBox-moreSelected": "{0} ancora",

        "vizExport-printingButtonText": "Stampa",
        "vizExport-titleMenuText": "Esportazione/Stampa",
        "vizExport-exportButtonText": "{0} file",

        "dxFilterBuilder-and": "E",
        "dxFilterBuilder-or": "O",
        "dxFilterBuilder-notAnd": "E non",
        "dxFilterBuilder-notOr": "O non",
        "dxFilterBuilder-addCondition": "Aggiungi condizione",
        "dxFilterBuilder-addGroup": "Aggiungi gruppo",
        "dxFilterBuilder-enterValueText": "<inserire un valore>",
        "dxFilterBuilder-filterOperationEquals": "Uguale a",
        "dxFilterBuilder-filterOperationNotEquals": "Diverso da",
        "dxFilterBuilder-filterOperationLess": "Minore di",
        "dxFilterBuilder-filterOperationLessOrEquals": "Minore o uguale a",
        "dxFilterBuilder-filterOperationGreater": "Maggiore di",
        "dxFilterBuilder-filterOperationGreaterOrEquals": "Maggiore o uguale a",
        "dxFilterBuilder-filterOperationStartsWith": "Inizia con",
        "dxFilterBuilder-filterOperationContains": "Contiene",
        "dxFilterBuilder-filterOperationNotContains": "Non contiene",
        "dxFilterBuilder-filterOperationEndsWith": "Termina con",
        "dxFilterBuilder-filterOperationIsBlank": "È vuoto",
        "dxFilterBuilder-filterOperationIsNotBlank": "Non è vuoto",
        "dxFilterBuilder-filterOperationBetween": "Compreso",
        "dxFilterBuilder-filterOperationAnyOf": "Include",
        "dxFilterBuilder-filterOperationNoneOf": "Non include",

        "dxHtmlEditor-dialogColorCaption": "Cambia il colore del testo",
        "dxHtmlEditor-dialogBackgroundCaption": "Cambia il colore di sfondo",
        "dxHtmlEditor-dialogLinkCaption": "Aggiungi Link",
        "dxHtmlEditor-dialogLinkUrlField": "URL",
        "dxHtmlEditor-dialogLinkTextField": "Testo",
        "dxHtmlEditor-dialogLinkTargetField": "Apri link in una nuova finestra",
        "dxHtmlEditor-dialogImageCaption": "Aggiungi Immagine",
        "dxHtmlEditor-dialogImageUrlField": "URL",
        "dxHtmlEditor-dialogImageAltField": "Testo alternativo",
        "dxHtmlEditor-dialogImageWidthField": "Larghezza (px)",
        "dxHtmlEditor-dialogImageHeightField": "Altezza (px)",
        "dxHtmlEditor-dialogInsertTableRowsField": "Righe",
        "dxHtmlEditor-dialogInsertTableColumnsField": "Colonne",
        "dxHtmlEditor-dialogInsertTableCaption": "Inserisci Tabella",
        "dxHtmlEditor-dialogUpdateImageCaption": "Aggiorna Immagine",
        "dxHtmlEditor-dialogImageUpdateButton": "Aggiorna",
        "dxHtmlEditor-dialogImageAddButton": "Aggiungi",
        "dxHtmlEditor-dialogImageSpecifyUrl": "Dal Web",
        "dxHtmlEditor-dialogImageSelectFile": "Da questo dispositivo",
        "dxHtmlEditor-dialogImageKeepAspectRatio": "Mantieni proporzioni",
        "dxHtmlEditor-dialogImageEncodeToBase64": "Codifica in Base64",
        "dxHtmlEditor-heading": "Intestazione",
        "dxHtmlEditor-normalText": "Testo Normale",
        "dxHtmlEditor-background": "Colore di Sfondo",
        "dxHtmlEditor-bold": "Grassetto",
        "dxHtmlEditor-color": "Colore Testo",
        "dxHtmlEditor-font": "Tipo di Carattere",
        "dxHtmlEditor-italic": "Corsivo",
        "dxHtmlEditor-link": "Aggiungi Link",
        "dxHtmlEditor-image": "Aggiungi Immagine",
        "dxHtmlEditor-size": "Dimensioni",
        "dxHtmlEditor-strike": "Barrato",
        "dxHtmlEditor-subscript": "Subscript",
        "dxHtmlEditor-superscript": "Superscript",
        "dxHtmlEditor-underline": "Sottolineato",
        "dxHtmlEditor-blockquote": "Citazione",
        "dxHtmlEditor-header": "Intestazione",
        "dxHtmlEditor-increaseIndent": "Aumenta Rientro",
        "dxHtmlEditor-decreaseIndent": "Riduci Rientro",
        "dxHtmlEditor-orderedList": "Elenco Ordinato",
        "dxHtmlEditor-bulletList": "Elenco Puntato",
        "dxHtmlEditor-alignLeft": "Allinea a Sinistra",
        "dxHtmlEditor-alignCenter": "Allinea al Centro",
        "dxHtmlEditor-alignRight": "Allinea a Destra",
        "dxHtmlEditor-alignJustify": "Giustificato",
        "dxHtmlEditor-codeBlock": "Codice",
        "dxHtmlEditor-variable": "Aggiungi Variabile",
        "dxHtmlEditor-undo": "Annulla",
        "dxHtmlEditor-redo": "Ripeti",
        "dxHtmlEditor-clear": "Rimuovi Formattazione",
        "dxHtmlEditor-insertTable": "Inserisci Tabella",
        "dxHtmlEditor-insertHeaderRow": "Inserisci Riga Intestazione",
        "dxHtmlEditor-insertRowAbove": "Inserisci Riga Sopra",
        "dxHtmlEditor-insertRowBelow": "Inserisci Riga Sotto",
        "dxHtmlEditor-insertColumnLeft": "Inserisci Colonna a Sinistra",
        "dxHtmlEditor-insertColumnRight": "Inserisci Colonna a Destra",
        "dxHtmlEditor-deleteColumn": "Elimina Colonna",
        "dxHtmlEditor-deleteRow": "Elimina Riga",
        "dxHtmlEditor-deleteTable": "Elimina Tabella",
        "dxHtmlEditor-cellProperties": "Proprietà Cella",
        "dxHtmlEditor-tableProperties": "Proprietà Tabella",
        "dxHtmlEditor-insert": "Inserisci",
        "dxHtmlEditor-delete": "Elimina",
        "dxHtmlEditor-border": "Bordo",
        "dxHtmlEditor-style": "Stile",
        "dxHtmlEditor-width": "Larghezza",
        "dxHtmlEditor-height": "Altezza",
        "dxHtmlEditor-borderColor": "Colore",
        "dxHtmlEditor-tableBackground": "Sfondo",
        "dxHtmlEditor-dimensions": "Dimensioni",
        "dxHtmlEditor-alignment": "Allineamento",
        "dxHtmlEditor-horizontal": "Orizzontale",
        "dxHtmlEditor-vertical": "Verticale",
        "dxHtmlEditor-paddingVertical": "Spaziatura Verticale",
        "dxHtmlEditor-paddingHorizontal": "Spaziatura Orizzontale",
        "dxHtmlEditor-pixels": "Pixel",
        "dxHtmlEditor-list": "Elenco",
        "dxHtmlEditor-ordered": "Ordinato",
        "dxHtmlEditor-bullet": "Puntato",
        "dxHtmlEditor-align": "Allineamento",
        "dxHtmlEditor-center": "Centro",
        "dxHtmlEditor-left": "Sinistra",
        "dxHtmlEditor-right": "Destra",
        "dxHtmlEditor-indent": "Rientro",
        "dxHtmlEditor-justify": "Giustificato",
        "dxHtmlEditor-borderStyleNone": "none",
        "dxHtmlEditor-borderStyleHidden": "hidden",
        "dxHtmlEditor-borderStyleDotted": "dotted",
        "dxHtmlEditor-borderStyleDashed": "dashed",
        "dxHtmlEditor-borderStyleSolid": "solid",
        "dxHtmlEditor-borderStyleDouble": "double",
        "dxHtmlEditor-borderStyleGroove": "groove",
        "dxHtmlEditor-borderStyleRidge": "ridge",
        "dxHtmlEditor-borderStyleInset": "inset",
        "dxHtmlEditor-borderStyleOutset": "outset",

        "dxFileManager-newDirectoryName": "Nuova cartella",
        "dxFileManager-rootDirectoryName": "Files",
        "dxFileManager-errorNoAccess": "Permesso di accesso negato. Impossibile proseguire.",
        "dxFileManager-errorDirectoryExistsFormat": "La cartella '{0}' gia' presente.",
        "dxFileManager-errorFileExistsFormat": "File '{0}' gia' esitente.",
        "dxFileManager-errorFileNotFoundFormat": "File '{0}' non trovato.",
        "dxFileManager-errorDirectoryNotFoundFormat": "La cartella '{0}' non presente.",
        "dxFileManager-errorWrongFileExtension": "File extension is not allowed.",
        "dxFileManager-errorMaxFileSizeExceeded": "Grandezza file oltre il limite impostato.",
        "dxFileManager-errorInvalidSymbols": "Caratteri non permessi nel nome.",
        "dxFileManager-errorDefault": "Errore non specificato.",
        "dxFileManager-errorDirectoryOpenFailed": "The directory cannot be opened",

        "dxFileManager-commandCreate": "Nuova cartella",
        "dxFileManager-commandRename": "Rinomina",
        "dxFileManager-commandMove": "Sposta",
        "dxFileManager-commandCopy": "Copia",
        "dxFileManager-commandDelete": "Cancella",
        "dxFileManager-commandDownload": "Scarica",
        "dxFileManager-commandUpload": "Carica i files",
        "dxFileManager-commandRefresh": "Aggiorna",
        "dxFileManager-commandThumbnails": "Vista miniature",
        "dxFileManager-commandDetails": "Vista di dettaglio",
        "dxFileManager-commandClearSelection": "Azzera selezione",
        "dxFileManager-commandShowNavPane": "Toggle navigation pane",

        "dxFileManager-dialogDirectoryChooserMoveTitle": "Sposta",
        "dxFileManager-dialogDirectoryChooserMoveButtonText": "Move",
        "dxFileManager-dialogDirectoryChooserCopyTitle": "Copia",
        "dxFileManager-dialogDirectoryChooserCopyButtonText": "Copia",
        "dxFileManager-dialogRenameItemTitle": "Rinomina",
        "dxFileManager-dialogRenameItemButtonText": "Salva",
        "dxFileManager-dialogCreateDirectoryTitle": "Nuova cartella",
        "dxFileManager-dialogCreateDirectoryButtonText": "Crea",
        "dxFileManager-dialogDeleteItemTitle": "Cancella",
        "dxFileManager-dialogDeleteItemButtonText": "Cancella",
        "dxFileManager-dialogDeleteItemSingleItemConfirmation": "Are you sure you want to delete {0}?",
        "dxFileManager-dialogDeleteItemMultipleItemsConfirmation": "Are you sure you want to delete {0} items?",
        "dxFileManager-dialogButtonCancel": "Annulla",

        "dxFileManager-editingCreateSingleItemProcessingMessage": "Creazione di una sotto-cartella {0}",
        "dxFileManager-editingCreateSingleItemSuccessMessage": "Creata una sotto-cartella {0}",
        "dxFileManager-editingCreateSingleItemErrorMessage": "La cartella non e' stata creata",
        "dxFileManager-editingCreateCommonErrorMessage": "La cartella non e' stata creata",

        "dxFileManager-editingRenameSingleItemProcessingMessage": "Rinomina di un elemento {0}",
        "dxFileManager-editingRenameSingleItemSuccessMessage": "Elemento rinominato {0}",
        "dxFileManager-editingRenameSingleItemErrorMessage": "Elemento non rinominato",
        "dxFileManager-editingRenameCommonErrorMessage": "Elemento non rinominato",

        "dxFileManager-editingDeleteSingleItemProcessingMessage": "Cancellazione di un elemento da {0}",
        "dxFileManager-editingDeleteMultipleItemsProcessingMessage": "Cancellazione di {0} elementi da {1}",
        "dxFileManager-editingDeleteSingleItemSuccessMessage": "Cancellazione di un elemento da {0}",
        "dxFileManager-editingDeleteMultipleItemsSuccessMessage": "Cancellati {0} elementi da {1}",
        "dxFileManager-editingDeleteSingleItemErrorMessage": "Item was not deleted",
        "dxFileManager-editingDeleteMultipleItemsErrorMessage": "{0} elementi non sono stati cancellati",
        "dxFileManager-editingDeleteCommonErrorMessage": "Some items were not deleted",

        "dxFileManager-editingMoveSingleItemProcessingMessage": "Spostamento di un elemento in {0}",
        "dxFileManager-editingMoveMultipleItemsProcessingMessage": "Spostati {0} elementi in {1}",
        "dxFileManager-editingMoveSingleItemSuccessMessage": "Spostato un elemento in {0}",
        "dxFileManager-editingMoveMultipleItemsSuccessMessage": "Spostati {0} elementi in {1}",
        "dxFileManager-editingMoveSingleItemErrorMessage": "L'elemento non e' stato spostato",
        "dxFileManager-editingMoveMultipleItemsErrorMessage": "{0} elementi non sono stati spostati",
        "dxFileManager-editingMoveCommonErrorMessage": "Some items were not moved",

        "dxFileManager-editingCopySingleItemProcessingMessage": "Copia di un elemento in {0}",
        "dxFileManager-editingCopyMultipleItemsProcessingMessage": "Copia di {0} elementi in {1}",
        "dxFileManager-editingCopySingleItemSuccessMessage": "Copiato un elemento in {0}",
        "dxFileManager-editingCopyMultipleItemsSuccessMessage": "Copiati {0} elementi in {1}",
        "dxFileManager-editingCopySingleItemErrorMessage": "Item was not copied",
        "dxFileManager-editingCopyMultipleItemsErrorMessage": "{0} elementi non sono stati copiati",
        "dxFileManager-editingCopyCommonErrorMessage": "Some items were not copied",

        "dxFileManager-editingUploadSingleItemProcessingMessage": "Caricamento di un elemento in {0}",
        "dxFileManager-editingUploadMultipleItemsProcessingMessage": "Caricamento di {0} elementi in {1}",
        "dxFileManager-editingUploadSingleItemSuccessMessage": "Caricato un elemento in {0}",
        "dxFileManager-editingUploadMultipleItemsSuccessMessage": "Caricati {0} elementi in {1}",
        "dxFileManager-editingUploadSingleItemErrorMessage": "Item was not uploaded",
        "dxFileManager-editingUploadMultipleItemsErrorMessage": "{0} elementi non sono stati caricati",
        "dxFileManager-editingUploadCanceledMessage": "Annullato",

        "dxFileManager-editingDownloadSingleItemErrorMessage": "Item was not downloaded",
        "dxFileManager-editingDownloadMultipleItemsErrorMessage": "{0} items were not downloaded",

        "dxFileManager-listDetailsColumnCaptionName": "Nome",
        "dxFileManager-listDetailsColumnCaptionDateModified": "Data modificata",
        "dxFileManager-listDetailsColumnCaptionFileSize": "Grandezza file",

        "dxFileManager-listThumbnailsTooltipTextSize": "Grandezza",
        "dxFileManager-listThumbnailsTooltipTextDateModified": "Date modificate",

        "dxFileManager-notificationProgressPanelTitle": "Progressione",
        "dxFileManager-notificationProgressPanelEmptyListText": "Nessuna operazione",
        "dxFileManager-notificationProgressPanelOperationCanceled": "Annullata",

        "dxDiagram-categoryGeneral": "General",
        "dxDiagram-categoryFlowchart": "Diagramma di flusso",
        "dxDiagram-categoryOrgChart": "Organigramma",
        "dxDiagram-categoryContainers": "Contenitore",
        "dxDiagram-categoryCustom": "Personalizzato",

        "dxDiagram-commandExportToSvg": "Esporta con formato SVG",
        "dxDiagram-commandExportToPng": "Esporta con formato PNG",
        "dxDiagram-commandExportToJpg": "Esporta con formato JPEG",
        "dxDiagram-commandUndo": "Annulla",
        "dxDiagram-commandRedo": "Ripristina",
        "dxDiagram-commandFontName": "Nome carattere",
        "dxDiagram-commandFontSize": "Grandezza carattere",
        "dxDiagram-commandBold": "Grassetto",
        "dxDiagram-commandItalic": "Italico",
        "dxDiagram-commandUnderline": "Sottolineato",
        "dxDiagram-commandTextColor": "Colore testo",
        "dxDiagram-commandLineColor": "Colore linea",
        "dxDiagram-commandLineWidth": "Line Width",
        "dxDiagram-commandLineStyle": "Line Style",
        "dxDiagram-commandLineStyleSolid": "Solid",
        "dxDiagram-commandLineStyleDotted": "Dotted",
        "dxDiagram-commandLineStyleDashed": "Dashed",
        "dxDiagram-commandFillColor": "Colore riempimento",
        "dxDiagram-commandAlignLeft": "Allineato a sinistra",
        "dxDiagram-commandAlignCenter": "Allineato al centro",
        "dxDiagram-commandAlignRight": "Allineato a destra",
        "dxDiagram-commandConnectorLineType": "Connettore lineare",
        "dxDiagram-commandConnectorLineStraight": "Connettore linea retta",
        "dxDiagram-commandConnectorLineOrthogonal": "Connettore ortogonale",
        "dxDiagram-commandConnectorLineStart": "Connettore iniziale",
        "dxDiagram-commandConnectorLineEnd": "Connettore finale",
        "dxDiagram-commandConnectorLineNone": "Connettore nessuna liena",
        "dxDiagram-commandConnectorLineArrow": "Connettore indicatore",
        "dxDiagram-commandFullscreen": "Schermo intero",
        "dxDiagram-commandUnits": "Unita'",
        "dxDiagram-commandPageSize": "Elementi per pagina",
        "dxDiagram-commandPageOrientation": "Orientamento",
        "dxDiagram-commandPageOrientationLandscape": "Orient. orizzonatale",
        "dxDiagram-commandPageOrientationPortrait": "Orient. verticale",
        "dxDiagram-commandPageColor": "Colore",
        "dxDiagram-commandShowGrid": "Mostra griglia",
        "dxDiagram-commandSnapToGrid": "Aggancia alla grigia",
        "dxDiagram-commandGridSize": "Grandezza griglia",
        "dxDiagram-commandZoomLevel": "Livello dettaglio",
        "dxDiagram-commandAutoZoom": "Dettaglio automatico",
        "dxDiagram-commandFitToContent": "Fit to Content",
        "dxDiagram-commandFitToWidth": "Fit to Width",
        "dxDiagram-commandAutoZoomByContent": "Auto Zoom by Content",
        "dxDiagram-commandAutoZoomByWidth": "Auto Zoom by Width",
        "dxDiagram-commandSimpleView": "Simple View",
        "dxDiagram-commandCut": "Taglia",
        "dxDiagram-commandCopy": "Copia",
        "dxDiagram-commandPaste": "Incolla",
        "dxDiagram-commandSelectAll": "Selez. tutto",
        "dxDiagram-commandDelete": "Cancella",
        "dxDiagram-commandBringToFront": "Primo piano",
        "dxDiagram-commandSendToBack": "Secondo piano",
        "dxDiagram-commandLock": "Blocca",
        "dxDiagram-commandUnlock": "Sblocca",
        "dxDiagram-commandInsertShapeImage": "Ins. immagine",
        "dxDiagram-commandEditShapeImage": "Edit. immagine",
        "dxDiagram-commandDeleteShapeImage": "Del. immagine",
        "dxDiagram-commandLayoutLeftToRight": "Left-to-right",
        "dxDiagram-commandLayoutRightToLeft": "Right-to-left",
        "dxDiagram-commandLayoutTopToBottom": "Top-to-bottom",
        "dxDiagram-commandLayoutBottomToTop": "Bottom-to-top",

        "dxDiagram-unitIn": "in",
        "dxDiagram-unitCm": "cm",
        "dxDiagram-unitPx": "px",

        "dxDiagram-dialogButtonOK": "Conferma",
        "dxDiagram-dialogButtonCancel": "Annulla",
        "dxDiagram-dialogInsertShapeImageTitle": "Ins. immagine",
        "dxDiagram-dialogEditShapeImageTitle": "Modifica immagine",
        "dxDiagram-dialogEditShapeImageSelectButton": "Sel. immagine",
        "dxDiagram-dialogEditShapeImageLabelText": "o trascina file qui",

        "dxDiagram-uiExport": "Export",
        "dxDiagram-uiProperties": "Properties",
        "dxDiagram-uiSettings": "Settings",
        "dxDiagram-uiShowToolbox": "Show Toolbox",
        "dxDiagram-uiSearch": "Search",
        "dxDiagram-uiStyle": "Style",
        "dxDiagram-uiLayout": "Layout",
        "dxDiagram-uiLayoutTree": "Tree",
        "dxDiagram-uiLayoutLayered": "Layered",
        "dxDiagram-uiDiagram": "Diagram",
        "dxDiagram-uiText": "Text",
        "dxDiagram-uiObject": "Object",
        "dxDiagram-uiConnector": "Connector",
        "dxDiagram-uiPage": "Page",

        "dxDiagram-shapeText": "Text",
        "dxDiagram-shapeRectangle": "Rectangle",
        "dxDiagram-shapeEllipse": "Ellipse",
        "dxDiagram-shapeCross": "Cross",
        "dxDiagram-shapeTriangle": "Triangle",
        "dxDiagram-shapeDiamond": "Diamond",
        "dxDiagram-shapeHeart": "Heart",
        "dxDiagram-shapePentagon": "Pentagon",
        "dxDiagram-shapeHexagon": "Hexagon",
        "dxDiagram-shapeOctagon": "Octagon",
        "dxDiagram-shapeStar": "Star",
        "dxDiagram-shapeArrowLeft": "Left Arrow",
        "dxDiagram-shapeArrowUp": "Up Arrow",
        "dxDiagram-shapeArrowRight": "Right Arrow",
        "dxDiagram-shapeArrowDown": "Down Arrow",
        "dxDiagram-shapeArrowUpDown": "Up Down Arrow",
        "dxDiagram-shapeArrowLeftRight": "Left Right Arrow",
        "dxDiagram-shapeProcess": "Process",
        "dxDiagram-shapeDecision": "Decision",
        "dxDiagram-shapeTerminator": "Terminator",
        "dxDiagram-shapePredefinedProcess": "Predefined Process",
        "dxDiagram-shapeDocument": "Document",
        "dxDiagram-shapeMultipleDocuments": "Multiple Documents",
        "dxDiagram-shapeManualInput": "Manual Input",
        "dxDiagram-shapePreparation": "Preparation",
        "dxDiagram-shapeData": "Data",
        "dxDiagram-shapeDatabase": "Database",
        "dxDiagram-shapeHardDisk": "Hard Disk",
        "dxDiagram-shapeInternalStorage": "Internal Storage",
        "dxDiagram-shapePaperTape": "Paper Tape",
        "dxDiagram-shapeManualOperation": "Manual Operation",
        "dxDiagram-shapeDelay": "Delay",
        "dxDiagram-shapeStoredData": "Stored Data",
        "dxDiagram-shapeDisplay": "Display",
        "dxDiagram-shapeMerge": "Merge",
        "dxDiagram-shapeConnector": "Connector",
        "dxDiagram-shapeOr": "Or",
        "dxDiagram-shapeSummingJunction": "Summing Junction",
        "dxDiagram-shapeContainerDefaultText": "Container",
        "dxDiagram-shapeVerticalContainer": "Vertical Container",
        "dxDiagram-shapeHorizontalContainer": "Horizontal Container",
        "dxDiagram-shapeCardDefaultText": "Person's Name",
        "dxDiagram-shapeCardWithImageOnLeft": "Card with Image on the Left",
        "dxDiagram-shapeCardWithImageOnTop": "Card with Image on the Top",
        "dxDiagram-shapeCardWithImageOnRight": "Card with Image on the Right",

        "dxGantt-dialogTitle": "Title",
        "dxGantt-dialogStartTitle": "Start",
        "dxGantt-dialogEndTitle": "End",
        "dxGantt-dialogProgressTitle": "Progress",
        "dxGantt-dialogResourcesTitle": "Resources",
        "dxGantt-dialogResourceManagerTitle": "Resource Manager",
        "dxGantt-dialogTaskDetailsTitle": "Task Details",
        "dxGantt-dialogEditResourceListHint": "Edit Resource List",
        "dxGantt-dialogEditNoResources": "No resources",
        "dxGantt-dialogButtonAdd": "Add",
        "dxGantt-contextMenuNewTask": "New Task",
        "dxGantt-contextMenuNewSubtask": "New Subtask",
        "dxGantt-contextMenuDeleteTask": "Delete Task",
        "dxGantt-contextMenuDeleteDependency": "Delete Dependency",
        "dxGantt-dialogTaskDeleteConfirmation": "Deleting a task also deletes all its dependencies and subtasks. Are you sure you want to delete this task?",
        "dxGantt-dialogDependencyDeleteConfirmation": "Are you sure you want to delete the dependency from the task?",
        "dxGantt-dialogResourcesDeleteConfirmation": "Deleting a resource also deletes it from tasks to which this resource is assigned. Are you sure you want to delete these resources? Resources: {0}",
        "dxGantt-dialogConstraintCriticalViolationMessage": "The task you are attempting to move is linked to a second task by a dependency relation. This change would conflict with dependency rules. How would you like to proceed?",
        "dxGantt-dialogConstraintViolationMessage": "The task you are attempting to move is linked to a second task by a dependency relation. How would you like to proceed?",
        "dxGantt-dialogCancelOperationMessage": "Cancel the operation",
        "dxGantt-dialogDeleteDependencyMessage": "Delete the dependency",
        "dxGantt-dialogMoveTaskAndKeepDependencyMessage": "Move the task and keep the dependency",
        "dxGantt-dialogConstraintCriticalViolationSeveralTasksMessage": "The task you are attempting to move is linked to another tasks by dependency relations. This change would conflict with dependency rules. How would you like to proceed?",
        "dxGantt-dialogConstraintViolationSeveralTasksMessage": "The task you are attempting to move is linked to another tasks by dependency relations. How would you like to proceed?",
        "dxGantt-dialogDeleteDependenciesMessage": "Delete the dependency relations",
        "dxGantt-dialogMoveTaskAndKeepDependenciesMessage": "Move the task and keep the dependencies",
        "dxGantt-undo": "Undo",
        "dxGantt-redo": "Redo",
        "dxGantt-expandAll": "Expand All",
        "dxGantt-collapseAll": "Collapse All",
        "dxGantt-addNewTask": "Add New Task",
        "dxGantt-deleteSelectedTask": "Delete Selected Task",
        "dxGantt-zoomIn": "Zoom In",
        "dxGantt-zoomOut": "Zoom Out",
        "dxGantt-fullScreen": "Full Screen",
        "dxGantt-quarter": "Q{0}",
        "dxGantt-sortingAscendingText": "Ordinamento ascendente",
        "dxGantt-sortingDescendingText": "Ordinamento discendente",
        "dxGantt-sortingClearText": "Annulla ordinamento",
        "dxGantt-showResources": "Show Resources",
        "dxGantt-showDependencies": "Show Dependencies",
        "dxGantt-dialogStartDateValidation": "Start date must be after {0}",
        "dxGantt-dialogEndDateValidation": "End date must be after {0}",

        "dxGallery-itemName": "Gallery item",

        "dxMultiView-elementAriaRoleDescription": "MultiView",
        "dxMultiView-elementAriaLabel": "Use the arrow keys or swipe to navigate between views",
        "dxMultiView-itemAriaRoleDescription": "View",
        "dxMultiView-itemAriaLabel": "{0} of {1}"
    }
}
