import { Inject, Injectable } from '@angular/core';
import {
    MAT_MOMENT_DATE_ADAPTER_OPTIONS,
    MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { TranslocoService } from '@jsverse/transloco';

@Injectable()
export class CustomDateAdapter extends MomentDateAdapter {
    constructor(
        @Inject(MAT_DATE_LOCALE) locale: string,
        private transloco: TranslocoService,
        @Inject(MAT_MOMENT_DATE_ADAPTER_OPTIONS) _options?: any
    ) {
        super(locale, _options);
        this.transloco.langChanges$.subscribe((lang) => this.setLocale(lang));
    }
}
