import {
    HttpEvent,
    HttpHandlerFn,
    HttpParams,
    HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { Observable } from 'rxjs';

export const cultureInterceptor = (
    req: HttpRequest<unknown>,
    next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
    const currentCulture = inject(TranslocoService).getActiveLang();

    const newReq = req.clone({
        params: (req.params ? req.params : new HttpParams()).set(
            'culture',
            currentCulture
        ),
    });

    return next(newReq);
};
