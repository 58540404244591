import { registerLocaleData } from '@angular/common';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import localeEn from '@angular/common/locales/en';
import localeIt from '@angular/common/locales/it';
import {
    ApplicationConfig,
    inject,
    provideAppInitializer,
} from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
    PreloadAllModules,
    provideRouter,
    withInMemoryScrolling,
    withPreloading,
} from '@angular/router';
import { provideFuse } from '@fuse';
import { TranslocoService, provideTransloco } from '@jsverse/transloco';
import { provideTranslocoLocale } from '@jsverse/transloco-locale';
import { appRoutes } from 'app/app.routes';
import { provideAuth } from 'app/core/auth/auth.provider';
import { provideIcons } from 'app/core/icons/icons.provider';
import { forkJoin } from 'rxjs';
import { CustomDateAdapter } from './core/date/custom-date-adapter';
import { CULTURE_STORAGE_KEY } from './core/transloco/transloco.consts';
import { TranslocoHttpLoader } from './core/transloco/transloco.http-loader';
import { cultureInterceptor } from './core/transloco/transloco.interceptor';
//Workaround imports to allow moment date adapter to work properly
//Without these moment imports, the dates get formatted without respecting
//the selected language locale
//https://github.com/angular/components/issues/28263
import 'moment/locale/en-gb.js';
import 'moment/locale/it';
import { MockApiService } from './mock-api';
import { LicenseService } from './modules/backoffice/license/license.service';

registerLocaleData(localeIt);
registerLocaleData(localeEn);

export const appConfig: ApplicationConfig = {
    providers: [
        provideAnimations(),
        provideHttpClient(withInterceptors([cultureInterceptor])),
        provideRouter(
            appRoutes,
            withPreloading(PreloadAllModules),
            withInMemoryScrolling({ scrollPositionRestoration: 'enabled' })
        ),
        provideTranslocoLocale({
            langToLocaleMapping: {
                it: 'it-IT',
                en: 'en-GB',
                de: 'de-DE',
            },
        }),
        // Material Date Adapter
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'LL',
                    timeInput: 'LT',
                },
                display: {
                    dateInput: 'LL',
                    timeInput: 'LT',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY',
                    timeOptionLabel: 'HH:mm',
                },
            },
        },
        { provide: DateAdapter, useClass: CustomDateAdapter },
        // Transloco Config
        provideTransloco({
            config: {
                availableLangs: [
                    {
                        id: 'en',
                        label: 'English',
                    },
                    {
                        id: 'it',
                        label: 'Italiano',
                    },
                ],
                defaultLang: localStorage.getItem(CULTURE_STORAGE_KEY) || 'it',
                fallbackLang: 'en',
                reRenderOnLangChange: true,
                prodMode: true,
            },
            loader: TranslocoHttpLoader,
        }),
        provideAppInitializer(() => {
            const translocoService = inject(TranslocoService);
            const licenseService = inject(LicenseService);
            const defaultLang = translocoService.getDefaultLang();
            translocoService.setActiveLang(defaultLang);

            // Fork join multiple API endpoint calls to wait all of them to finish
            return forkJoin([
                translocoService.load(defaultLang),
                licenseService.refreshLicenseInfo(),
            ]);
        }),

        // Fuse
        provideAuth(),
        provideIcons(),
        provideFuse({
            mockApi: {
                delay: 0,
                service: MockApiService,
            },
            fuse: {
                layout: 'classic',
                scheme: 'auto',
                screens: {
                    sm: '600px',
                    md: '960px',
                    lg: '1280px',
                    xl: '1440px',
                },
                theme: 'theme-brand',
                themes: [
                    {
                        id: 'theme-default',
                        name: 'Default',
                    },
                    {
                        id: 'theme-brand',
                        name: 'Brand',
                    },
                    {
                        id: 'theme-teal',
                        name: 'Teal',
                    },
                    {
                        id: 'theme-rose',
                        name: 'Rose',
                    },
                    {
                        id: 'theme-purple',
                        name: 'Purple',
                    },
                    {
                        id: 'theme-amber',
                        name: 'Amber',
                    },
                ],
            },
        }),
    ],
};
