import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { of } from 'rxjs';
import { UserService } from '../../user/user.service';
import { SsPermission } from '../auth.types';

export const PermissionGuard: CanActivateFn | CanActivateChildFn = (
    route,
    state
) => {
    const router: Router = inject(Router);
    const userService = inject(UserService);
    const permission = <SsPermission>route.data;

    // Check the permission status
    if (
        userService.hasPermission(permission.permission, permission.accessLevel)
    ) {
        return of(true);
    } else {
        let urlTree = router.parseUrl('forbidden');
        return of(urlTree);
    }
};
